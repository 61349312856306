import { createNotification, enqueueSnackbar } from "@onpreo/slices";
import { createAppAsyncThunk } from "..";
import agentService from "../services/agent.service";
import contactService from "../services/contact.service";
import { setAgent } from "../slices/agent.slice";
import { setContact, setGuests } from "../slices/contact.slice";
import { setGuestRealEstatesChanges, setRealEstateChanges } from "../slices/real-estate-changes.slice";
import { setRealEstate, setGuestRealEstates } from "../slices/real-estate.slice";
import realEstateChangesService from "../services/real-estate-changes.service";

// Load an initial state for the guest app
export const $loadSession = createAppAsyncThunk("session/$loadSession", async (_, thunkAPI) => {
    // start by fetching the contact
    const response = await contactService.fetchContacts();
    const { contact, contactList, realEstates } = response.data;

    // if (contact.realEstates.length === 0) return;
    thunkAPI.dispatch(setContact(contact));
    thunkAPI.dispatch(setGuests(contactList));
    thunkAPI.dispatch(setGuestRealEstates(realEstates));
    thunkAPI.dispatch(setRealEstate({ active: 0 }));

    // fetch real estate changes
    const realEstateChangesResp = await realEstateChangesService.fetchRealEstateChanges();
    const { realEstateChanges } = realEstateChangesResp.data;
    thunkAPI.dispatch(setGuestRealEstatesChanges(realEstateChanges));
    thunkAPI.dispatch(setRealEstateChanges({ active: 0 }));

    // and we also need the agent settings
    const agentResp = await agentService.fetchAgent();
    thunkAPI.dispatch(setAgent(agentResp.data.agent));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Willkommen bei onpreo", "success")));
});

export const $loadWorkspaceSession = createAppAsyncThunk("session/$loadWorkspaceSession", async (_, thunkAPI) => {
    // start by fetching the contact
    const response = await contactService.loadSpecificWorkspaceContacts();
    const { contact, contactList, realEstates } = response.data;

    // if (contact.realEstates.length === 0) return;
    thunkAPI.dispatch(setContact(contact));
    thunkAPI.dispatch(setGuests(contactList));
    thunkAPI.dispatch(setGuestRealEstates(realEstates));
    thunkAPI.dispatch(setRealEstate({ active: 0 }));

    // fetch real estate changes
    const realEstateChangesResp = await realEstateChangesService.fetchRealEstateChanges();
    const { realEstateChanges } = realEstateChangesResp.data;
    thunkAPI.dispatch(setGuestRealEstatesChanges(realEstateChanges));
    thunkAPI.dispatch(setRealEstateChanges({ active: 0 }));

    // and we also need the agent settings
    const agentResp = await agentService.fetchAgent();
    thunkAPI.dispatch(setAgent(agentResp.data.agent));
    thunkAPI.dispatch(enqueueSnackbar(createNotification("Willkommen bei onpreo", "success")));
});
