import ReduxState from "../models";
import { createSelector } from "@reduxjs/toolkit";

export const getAgent = (state: ReduxState) => state.agent;

export const getAgentMeta = createSelector(getAgent, agent => agent?.meta);
export const getAgentName = createSelector(getAgentMeta, meta => meta?.name);
export const getAgentPhone = createSelector(getAgentMeta, meta => meta?.phone);
export const getAgentEmail = createSelector(getAgentMeta, meta => meta?.email);
export const getAgentImage = createSelector(getAgentMeta, meta => meta?.img);
export const getAgentGender = createSelector(getAgent, agent => agent?.gender);
export const getAgentId = createSelector(getAgent, agent => agent?.id);

export const getCompany = createSelector(getAgent, agent => agent?.company);
export const getCompanyCompany = createSelector(getCompany, c => c?.company);
export const getCompanyWebsite = createSelector(getCompany, c => c?.website);

export const getWorkspace = createSelector(getAgent, agent => agent?.workspace);
export const getWorkspaceId = createSelector(getWorkspace, ws => ws?.id);
// export const getAgentImage = createSelector(getWorkspace, ws => ws?.agentImage); // why check in workspace when its already available in meta?
export const getAgentSettings = createSelector(getWorkspace, ws => ws?.agentSettings);
export const getDefaultImage = createSelector(getAgent, agent => agent?.meta?.img);
