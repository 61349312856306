import { RealEstateChangesDocument } from "@onpreo/database";
import { DeepPartial } from "react-hook-form";
import { onpreoClient } from "@onpreo/upsy-daisy/client";

const fetchRealEstateChanges = async () => {
    const url = "/api/real-estate-changes";
    return await onpreoClient.get<{
        realEstateChanges: RealEstateChangesDocument[];
    }>(url);
};

const updateRealEstateChanges = async (realEstateChangeId: string, realEstateChanges: DeepPartial<RealEstateChangesDocument>, variant?: string) => {
    const url = `/api/real-estate-changes/${realEstateChangeId}`;
    return await onpreoClient.put<{ realEstateChanges: RealEstateChangesDocument }>(url, { realEstateChanges, variant });
};

const realEstateChangesService = {
    updateRealEstateChanges,
    fetchRealEstateChanges
};

export default realEstateChangesService;
