import ReduxState from "../models";
import { createSelector } from "@reduxjs/toolkit";

export const getContactState = (state: ReduxState) => state.contact;
export const getContact = createSelector(getContactState, c => c.currentContact);
export const getGuestList = createSelector(getContactState, c => c.guests);

export const getContactId = createSelector(getContact, user => (user as any)?._id);

// export const getContactRealEstates = createSelector(getContact, c => c.realEstates);

export const getMode = createSelector(getContactState, c => c.mode);
