import { useSession } from "next-auth/react";
import { Fragment, useEffect } from "react";
import { useAppDispatch } from "../../store";
import { $loadSession, $loadWorkspaceSession } from "../../store/thunks/session.thunk";
import { getContactId } from "../../store/selectors/contact.selectors";
import { useReduxSelector } from "../../store/selectors";
import { setMode } from "../../store/slices/contact.slice";

const SessionChecker = () => {
    const { data: session, status } = useSession();
    const dispatch = useAppDispatch();
    const contactId = useReduxSelector(getContactId);

    useEffect(() => {
        if (status === "authenticated" && !contactId && session?.user?.email) {
            (async () => {
                // @ts-ignore
                if (session.accessToken === "demo-mode") {
                    dispatch(setMode({ mode: "demo" }));
                    await dispatch($loadWorkspaceSession());
                    // @ts-ignore
                } else if (session.accessToken === "workspace-mode") {
                    dispatch(setMode({ mode: "workspace" }));
                    await dispatch($loadWorkspaceSession());
                } else {
                    dispatch(setMode({ mode: "default" }));
                    await dispatch($loadSession());
                }
            })();
        }
    }, [session?.user, contactId, status, dispatch]);

    return <Fragment />;
};

export default SessionChecker;
