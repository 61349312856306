import { useRouter } from "next/router";
import { useEffect } from "react";
import { Userpilot } from "userpilot";
import { useReduxSelector } from "../../store/selectors";
import { getAgentEmail, getAgentId, getAgentName, getCompanyCompany, getWorkspaceId } from "../../store/selectors/agent.selector";
import { USERPILOT_ACTIVE_TRACKING, USERPILOT_KEY } from "../../utils/secrets";

// Initialize Userpilot
Userpilot.initialize(USERPILOT_KEY);

const UserPilot = () => {
    const router = useRouter();
    const agentId = useReduxSelector(getAgentId);
    const agentName = useReduxSelector(getAgentName);
    const agentEmail = useReduxSelector(getAgentEmail);
    const company = useReduxSelector(getCompanyCompany);
    const workspaceId = useReduxSelector(getWorkspaceId);

    useEffect(() => {
        if (agentId) {
            console.log("updating userpilot user");
            if (USERPILOT_ACTIVE_TRACKING)
                Userpilot.identify(agentId, {
                    name: agentName,
                    email: agentEmail,
                    company: {
                        id: workspaceId,
                        name: company ?? "Unknown Company Name"
                    }
                });
        }
    }, [agentId]);

    useEffect(() => {
        if (agentId) {
            Userpilot.reload();
        }
    }, [router.route]);

    return <div id="user-pilot-helper" />;
};

export const userPilotTrigger = {
    tourStart: () => Userpilot.trigger("1676491254mMdr0294")
};

export default UserPilot;
