import { ContactDocument, WorkspaceDocument, RealEstateDocument } from "@onpreo/database";
import { onpreoClient } from "@onpreo/upsy-daisy/client";

import axios from "axios";

const fetchContacts = async () => {
    const url = "/api/contacts/";
    return await onpreoClient.get<{
        contact: ContactDocument;
        realEstates: RealEstateDocument[];
        contactList: ContactDocument[];
    }>(url);
};

const updateContact = async (contactId: string, contact: any) => {
    const url = `/api/contacts/${contactId}`;
    return await onpreoClient.put(url, { contact });
};

const deleteContact = async (contactId: string) => {
    const url = `/api/contacts/${contactId}`;
    return await onpreoClient.delete(url);
};

const deleteAllAccounts = async () => {
    const url = `/api/contacts`;
    return await onpreoClient.delete(url);
};

const notifyAllOwners = async emailIdList => {
    const url = "/api/contacts/";
    return await onpreoClient.post(url, emailIdList);
};

const sendNotaryDataReadyEmail = async (payload: { contactId: string; realEstateId: string }) => {
    const url = `/api/contacts/${payload.contactId}/${payload.realEstateId}/notary-data-ready-email`;
    return await onpreoClient.post(url);
};

const sendNoEnergyCertificateEmail = async (payload: { contactId: string; realEstateId: string }) => {
    const url = `/api/contacts/${payload.contactId}/${payload.realEstateId}/no-energy-certificate`;
    return await onpreoClient.post(url);
};

const sendExposeDataReadyEmail = async (payload: { contactId: string; realEstateId: string }) => {
    const url = `/api/contacts/${payload.contactId}/${payload.realEstateId}/expose-data-ready-email`;
    return await onpreoClient.post(url);
};

const sendNotOwnerEmail = async (payload: { contactId: string; realEstateId: string }) => {
    const url = `/api/contacts/${payload.contactId}/not-owner-email`;
    return await onpreoClient.post(url, { realEstateId: payload.realEstateId });
};

const sendAppointmentSuggestionEmail = async (contactId: string) => {
    const url = `/api/contacts/${contactId}/appointment-suggestion-email`;
    return await onpreoClient.post(url);
};

const sendBrokerDealDoneEmail = async (payload: { contactId: string; realEstateId: string; variant?: string }) => {
    const url = `/api/contacts/${payload.contactId}/broker-deal-done-email`;
    return await onpreoClient.post(url, { realEstateId: payload.realEstateId, variant: payload.variant });
};

const setContactPassword = async (contactId, passwordDetails) => {
    const url = `/api/contacts/${contactId}/set-password`;
    return await onpreoClient.post(url, { contactId, passwordDetails });
};

const getPriceAssistantPdf = async (contactId: string, realEstateId: string) => {
    const url = `/api/contacts/${contactId}/${realEstateId}/price-assistant-pdf`;
    return await onpreoClient.get(url);
};

const resetContactPassword = async passwordDetails => {
    const url = "/api/contacts/password-reset/change-password";
    return await onpreoClient.post(url, { passwordDetails });
};

const loadSpecificWorkspaceContacts = async () => {
    const url = "/api/contacts/workspace";
    return await onpreoClient.get<{
        contact: ContactDocument;
        realEstates: RealEstateDocument[];
        contactList: ContactDocument[];
    }>(url);
};

const checkIfContactExistInWorkspace = async (workspaceId: string, email: string) => {
    const url = `/api/contacts/workspace/${workspaceId}?email=${email}`;
    return await onpreoClient.get(url);
};

const signupContactWithWorkspaceId = async ({ workspaceId, passwordDetails }: { workspaceId: string; passwordDetails: any }) => {
    const url = `/api/contacts/workspace/${workspaceId}?email=${passwordDetails?.email}`;
    return await onpreoClient.post(url, { passwordDetails });
};

const contactService = {
    fetchContacts,
    updateContact,
    deleteContact,
    deleteAllAccounts,
    notifyAllOwners,
    sendNotOwnerEmail,
    sendNotaryDataReadyEmail,
    sendNoEnergyCertificateEmail,
    sendExposeDataReadyEmail,
    sendAppointmentSuggestionEmail,
    sendBrokerDealDoneEmail,
    setContactPassword,
    resetContactPassword,
    getPriceAssistantPdf,
    loadSpecificWorkspaceContacts,
    checkIfContactExistInWorkspace,
    signupContactWithWorkspaceId
};

export default contactService;
