import React, { Fragment, useEffect } from "react";
import { ThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import "../public/styles.css";
import Head from "next/head";
import { Provider } from "react-redux";
import { onpreoTheme } from "@onpreo/components";
import "../src/assets/scss/argon-dashboard-pro-material-ui.scss";
import { SessionProvider, signIn, useSession } from "next-auth/react";
import SessionChecker from "../src/components/helpers/session-checker";
import { CircularProgress } from "@material-ui/core";
import dynamic from "next/dynamic";
import { initStore } from "../src/store";
import { Snacker } from "@onpreo/slices";
import UserPilot from "../src/components/helpers/userpilot-helper";
const Navigation = dynamic(import("../src/components/navigation"));

export default function OnpreoApp({ Component, pageProps: { session, ...pageProps } }) {
    const store = initStore(pageProps.initialReduxState);

    useEffect(() => {
        // Remove the server-side injected CSS.
        const jssStyles = document.querySelector("#jss-server-side");
        if (jssStyles && jssStyles.parentElement) jssStyles.parentElement.removeChild(jssStyles);
    }, []);

    return (
        <Fragment>
            <Head>
                <title>onpreo</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0" />
            </Head>
            <ThemeProvider theme={onpreoTheme}>
                <SessionProvider session={session}>
                    <Provider store={store}>
                        <Snacker />
                        <CssBaseline />
                        {Component.authenticated ? (
                            <Authenticator>
                                <UserPilot />
                                <SessionChecker />
                                {!Component.authenticated.ignoreNav && (
                                    <Navigation>
                                        <Component {...pageProps} />
                                    </Navigation>
                                )}
                                {Component.authenticated.ignoreNav && <Component {...pageProps} />}
                            </Authenticator>
                        ) : (
                            <Component {...pageProps} />
                        )}
                    </Provider>
                </SessionProvider>
            </ThemeProvider>
        </Fragment>
    );
}

function Authenticator({ children }: any) {
    const { data: session, status } = useSession();
    const isUser = !!session?.user;

    useEffect(() => {
        if (status === "loading") return; // Do nothing while loading
        if (!session?.user) signIn().then(); // If not authenticated, force log in
    }, [session?.user, status]);

    if (isUser) {
        return children;
    }

    // Session is being fetched, or no user.
    // If no user, useEffect() will redirect.
    return <CircularProgress />;
}
