import { onpreoClient } from "@onpreo/upsy-daisy/client";
import { AgentState } from "../models/agent.state";

const fetchAgent = async () => {
    const url = `/api/user`;
    return await onpreoClient.get<{ agent: AgentState }>(url);
};

const agentService = {
    fetchAgent
};

export default agentService;
